import React, { useState, useRef, useEffect } from 'react'
import Swipers, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import classnames from 'classnames'
import * as styles from './index.module.less'
import 'swiper/swiper-bundle.css'
Swipers.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])

interface Ds2022GuestsSwiperPropsState {
  className?: string
}

const Ds2022GuestsSwiper: React.FC<Ds2022GuestsSwiperPropsState> = ({ children, className }) => {
  const swiper = useRef<any>(null)
  useEffect(() => {
    swiper.current = new Swipers('.myswiper', {
      slidesPerView: 1,
      speed: 800,
      // autoplay: true,
      //   loop: true,
      allowTouchMove: false,
      //   autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    // const swiperElement = swiper.current?.$el[0]
    // if (swiperElement) {
    //   swiperElement?.addEventListener('mouseenter', () => {
    //     swiper.current.autoplay.stop()
    //     swiperElement?.addEventListener('mousemove', () => {})
    //   })
    //   swiperElement.addEventListener('mouseleave', () => {
    //     swiper.current?.autoplay.start()
    //   })
    // }
    return () => {
      // if (swiperElement) {
      //   swiper.current.detachEvents()
      //   swiper.current.destroy()
      // }
    }
  }, [])
  return (
    <div className={classnames('h-[650px] w-[1200px] mx-auto relative ', styles.swiperContainer, className)}>
      <div className="myswiper swiper-container  mx-auto h-full">
        <div className="swiper-wrapper">{children}</div>
      </div>
      <div className={classnames(styles['swiperButtonNext'], 'swiper-button-next')} />
      <div className={classnames(styles['swiperButtonPrev'], 'swiper-button-prev')} />
    </div>
  )
}

export default Ds2022GuestsSwiper
