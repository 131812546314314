/*
 * @Author: liyu
 * @Date: 2022-09-01 14:17:00
 * @LastEditTime: 2022-09-20 11:42:40
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022LightCard/index.tsx
 */
import React, { useEffect, ReactNode, useRef, useState } from 'react'
import classnames from 'classnames'
import { Link } from 'gatsby'
import * as styles from './index.module.less'
import { isPc } from 'utils/utils'
import { saTrackDs2022ButtonClick } from '../../../utils/statistics'

interface Ds2022LightCardPropsCard {
  className: any
  dataSource: {
    number: string
    title: string
    desc: string
    btn_text: string
    btn_href: string
  }
  cardClassName?: string
  onOpenPopup?: () => void
}

const Ds2022LightCard: React.FC<Ds2022LightCardPropsCard> = ({
  cardClassName = '',
  className,
  dataSource,
  onOpenPopup,
}) => {
  const { title, btn_href, btn_text, desc, number } = dataSource
  const handleClick = () => {
    btn_text === '免费报名直播' && saTrackDs2022ButtonClick('亮点线上')
    if (btn_text !== '立即探索') {
      window.open(btn_href, '_blank')
    } else {
      onOpenPopup?.()
    }
  }

  // const curCard = useRef<HTMLDivElement>(null)
  // const [flag, setFlag] = useState<boolean | string>(false)
  // useEffect(() => {
  //   if (isActive || !isPc()) setFlag(true)
  //   const handleMouseEnter = () => {
  //     setFlag(true)
  //   }
  //   const handleMouseLeave = () => {
  //     setFlag(false)
  //   }
  //   if (curCard.current && isPc() && !isActive) {
  //     curCard.current.addEventListener('mouseenter', handleMouseEnter)
  //     curCard.current.addEventListener('mouseleave', handleMouseLeave)
  //   }
  //   return () => {
  //     if (curCard.current && isPc() && !isActive) {
  //       curCard.current.removeEventListener('mouseenter', handleMouseEnter)
  //       curCard.current.removeEventListener('mouseleave', handleMouseLeave)
  //     }
  //   }
  // }, [])
  return (
    <>
      <div
        // ref={curCard}
        className={classnames(
          className,
          styles.lightContainer,
          'block mt-[0.8rem] lg:mt-0 px-[2rem]  pb-[2.8rem] pt-[1rem] lg:px-[40px] lg:pt-[22px] lg:pb-[40px]',
          styles[cardClassName],
        )}
        onClick={handleClick}
        // target="_blank"
        // href={btn_href}
      >
        <div className={classnames('lg:flex lg:items-center')}>
          <span className={classnames('text-[24px] lg:text-[80px] block lg:inline', styles.lightTitle)}>{number}</span>
          <span className={classnames(styles.lightTitle, ' text-[24px] lg:ml-[18px]')}>{title}</span>
        </div>
        <p className={classnames(styles.lightDesc, 'mt-[7px] lg:mt-0 text-[14px] leading-[24px] text-[#fff]')}>
          {desc}
        </p>
        <p className="mt-[24px] lg:mt-[50px]">
          <div
            className={classnames(styles.lightLink, 'inline-block leading-[33px] px-[17px] text-[#fff] text-[14px]')}
            // href={btn_href}
            // onClick={() => {
            //   // btn_text === '免费报名直播' && saTrackDs2022ButtonClick('亮点线上')

            // }}
            // onClick={handleClick}
            // target="_blank"
          >
            <span className={classnames('rounded-[17px] inline-block  w-full h-full', styles.lightDesc)}>
              {btn_text}
            </span>
          </div>
        </p>
      </div>
    </>
  )
}

export default Ds2022LightCard
