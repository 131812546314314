/*
 * @Author: liyu
 * @Date: 2022-09-02 11:28:05
 * @LastEditTime: 2022-09-22 15:36:17
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022GuestsMbSwiper/index.tsx
 */
import React, { useEffect, useRef } from 'react'
import Swipers, {
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom,
} from 'swiper'
import classnames from 'classnames'
import 'swiper/swiper-bundle.css'
import * as styles from './index.module.less'
Swipers.use([
  A11y,
  Autoplay,
  Controller,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
  HashNavigation,
  History,
  Keyboard,
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Parallax,
  Scrollbar,
  Thumbs,
  Virtual,
  Zoom,
])
interface Ds2022GuestsMbSwiperPropsState {
  guest_dataSource_mb: any[]
}
const Ds2022GuestsMbSwiper: React.FC<Ds2022GuestsMbSwiperPropsState> = ({ guest_dataSource_mb }) => {
  const galleryThumbs = useRef<any>(null)
  const galleryTop = useRef<any>(null)
  useEffect(() => {
    galleryThumbs.current = new Swipers('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 4,
      // loop: true,
      //   autoplay: true,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      // pagination: {
      //   el: '.guests-swiper-pagination',
      //   type: 'bullets',
      // },
    })
    galleryTop.current = new Swipers('.gallery-top', {
      spaceBetween: 20,
      //   autoplay: true,
      // loop: true,
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: '.guests-swiper-button-next',
        prevEl: '.guests-swiper-button-prev',
      },

      thumbs: {
        swiper: galleryThumbs.current,
      },
    })
  }, [])
  return (
    <div className={classnames('lg:hidden block   text-[#fff]', styles.swiperContainer)}>
      <div className="swiper-container gallery-top">
        <div className="swiper-wrapper">
          {guest_dataSource_mb?.map((item, index) => {
            return (
              <div className="swiper-slide relative" key={index}>
                <img className="w-full" src={item?.person_photo_mb?.publicURL || item?.person_photo_mb} alt="" />
                <p className="absolute top-0 text-center w-full h-auto text-[#fff]">
                  <span className="text-[22px] inline-block pl-[7rem]">{item?.person_name}</span>
                  <br />
                  <span className="text-[14px] inline-block  pl-[7rem]">{item?.person_position}</span>
                </p>
                <p className="absolute scale-[0.9] bottom-[4px] left-[14px]   max-w-[350px] h-[44px]  leading-[12px]">
                  {item?.person_desc}
                </p>
              </div>
            )
          })}
        </div>
        {/* <div className="swiper-pagination "></div> */}
      </div>
      <div className="swiper-container gallery-thumbs !pt-[2rem]">
        <div className="swiper-wrapper">
          {guest_dataSource_mb?.map((item, index) => {
            return (
              <div
                className="swiper-slide w-[8rem] !h-[8rem]"
                style={{
                  background: `url(${item.person_img_mb?.publicURL || item?.person_img_mb}) no-repeat`,
                  backgroundSize: '100% 100%',
                }}
                key={index}
              ></div>
            )
          })}
        </div>
        {/* <div className="guests-swiper-pagination lg:hidden"></div> */}
        {/* <!-- A.6dd Arrows --> */}
      </div>
      <div className="guests-swiper-button-next swiper-button-white lg:hidden"></div>
      <div className="guests-swiper-button-prev swiper-button-white lg:hidden"></div>
    </div>
  )
}

export default Ds2022GuestsMbSwiper
