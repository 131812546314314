import React, { ReactNode, useEffect, useRef, useState } from 'react'
import Layout from 'layouts/layout'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from 'components/seo'
import Header from 'components/Topic2022/Header'
import Ds2022IntegralBanner from 'components/Topic2022/Ds2022IntegralBanner'
import Ds2022IntegralCard from 'components/Topic2022/Ds2022IntegralCard'
import Ds2022LightCard from 'components/Topic2022/Ds2022LightCard'
import Ds2022GuestsSwiper from 'components/Topic2022/Ds2022GuestsSwiper'
import Ds2022GuestsSwiperSlide from 'components/Topic2022/Ds2022GuestsSwiperSlide'
import Ds2022GuestsMbSwiper from 'components/Topic2022/Ds2022GuestsMbSwiper'
import Ds2022ReviewSwiper from 'components/Topic2022/Ds2022ReviewSwiper'
import Schedule from 'components/Topic2022/Ds2022Schedule'
import Ds2022Footer from 'components/Topic2022/Ds2022Footer'
import Ds2022Popup from 'components/Topic2022/Ds2022Popup'
import classnames from 'classnames'
import * as styles from './index.module.less'
import { isPc } from 'utils/utils'

/**
 * 数据维护说明
 * 驱动大会总览页数据维护地址: src/data/ds2022/ds2022
 */

const DS2022: React.FC = (prop) => {
  const { ds2022Yaml } = useStaticQuery(graphql`
    query {
      ds2022Yaml {
        title
        desc
        keywords
        saTitle
        header_icon
        home_icon
        entrance_one_text
        entrance_one_href
        entrance_two_text
        entrance_two_href
        video_url
        coord_place
        time
        coord_icon
        time_icon
        arrows_icon
        banner_mb
        ds2022_icon_mb {
          childImageSharp {
            gatsbyImageData(formats: PNG)
          }
        }
        light_title
        mask_title
        dataSource_one {
          title
          number
          desc
          btn_text
          btn_href
          cardClassName
        }
        dataSource_two {
          title
          number
          desc
          btn_text
          btn_href
          cardClassName
        }
        dataSource_three {
          title
          number
          desc
          btn_text
          btn_href
          cardClassName
        }
        guest_title
        guest_mask_title
        guest_dataSource {
          bottom {
            person_desc
            person_name
            person_position
            person_img {
              childImageSharp {
                gatsbyImageData(formats: PNG)
              }
            }
          }
          name
          top {
            person_desc
            person_name
            person_position
            person_img {
              childImageSharp {
                gatsbyImageData(formats: PNG)
              }
            }
          }
        }
        guest_dataSource_mb {
          person_name
          person_position
          person_desc
          person_photo_mb
          person_img_mb
        }
        schedule_title
        schedule_mask_title
        schedule_dataSource {
          screen
          top {
            dataTime
            OTO
            title
            schedule_title
            dialog
            dialog_active
            agendas_img
          }
          bottom {
            link
            left {
              time_dataSource
              agenda
            }
            right {
              headline
              person_name
              person_position
              dataTime
              duration
            }
          }
        }
        online_title
        online_mask_title
        review_title
        review_mask_title
        review_dataSource {
          number
          link
          img_url {
            childImageSharp {
              gatsbyImageData(formats: JPG)
            }
          }
          year
          desc
        }
        footer_title
        footer_code
        footer_msg
      }
    }
  `)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const eleNavigation = useRef<any>()
  const rightPoint = useRef<any>()
  const [flag, setFlag] = useState<boolean>(false)
  const [popupShow, setPopupShow] = useState<boolean>(false)
  const handleClickArrow = () => {
    const contents: any = document.querySelector('.ds2022_container[data-n=大会亮点]')
    if (isPc()) {
      document.documentElement.scrollTop = contents?.offsetTop
    }
  }
  const handleClosePopup = () => {
    setPopupShow(false)
  }
  const handleOpenPopup = () => {
    window.open('/democenter/overview.html')
    //setPopupShow(true)
  }
  useEffect(() => {
    let offsetTopArr: any = []
    let nowScrollTop = document.documentElement.scrollTop
    const handleClick = (event: any) => {
      if (event.target?.tagName?.toLowerCase() == 'div') {
        const n = event.target?.getAttribute('data-n')
        const contentPart: HTMLLIElement | null = document.querySelector('.ds2022_container[data-n=' + n + ']')
        if (contentPart) document.documentElement.scrollTop = contentPart?.offsetTop
      }
    }
    const handleScroll = () => {
      nowScrollTop = document.documentElement.scrollTop
      if (nowScrollTop >= offsetTopArr[0]) {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '1'
          rightPoint.current.style.opacity = '1'
        }
      } else {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '0'
          rightPoint.current.style.opacity = '0'
        }
      }
      offsetTopArr.map((topItem, index) => {
        if (nowScrollTop >= topItem && nowScrollTop < offsetTopArr[index + 1]) {
          setCurrentIndex(index)
          return
        }
      })
    }
    if (isPc() && eleNavigation.current) {
      eleNavigation.current?.addEventListener('click', handleClick)

      // 监听滚动
      const contents: any = document.querySelectorAll('.ds2022_container')

      for (let i = 0; i < contents.length; i++) {
        offsetTopArr.push(contents[i]?.offsetTop - 200)
      }
      // 为了方便比较，追加无穷大
      offsetTopArr.push(Infinity)

      if (nowScrollTop >= offsetTopArr[0]) {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '1'
          rightPoint.current.style.opacity = '1'
        }
      } else {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '0'
          rightPoint.current.style.opacity = '0'
        }
      }
      offsetTopArr.map((topItem, index) => {
        if (nowScrollTop >= topItem && nowScrollTop < offsetTopArr[index + 1]) {
          setCurrentIndex(index)
          return
        }
      })
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (isPc() && eleNavigation.current) {
        eleNavigation.current.removeEventListener('click', handleClick)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={ds2022Yaml?.title}
        description={ds2022Yaml?.desc}
        keywords={ds2022Yaml?.keywords}
        saTitle={ds2022Yaml?.saTitle}
      />
      {/* 头部 */}
      <Header
        imgUrl={ds2022Yaml?.header_icon?.publicURL || ds2022Yaml?.header_icon}
        entrance_one_text={ds2022Yaml?.entrance_one_text}
        entrance_one_href={ds2022Yaml?.entrance_one_href}
        entrance_two_text={ds2022Yaml?.entrance_two_text}
        entrance_two_href={ds2022Yaml?.entrance_two_href}
        home_icon={ds2022Yaml?.home_icon?.publicURL || ds2022Yaml?.home_icon}
      />
      <main>
        <Ds2022IntegralBanner
          className="relative  lg:min-h-[100vh]"
          video_url={ds2022Yaml?.video_url}
          banner_mb={ds2022Yaml?.banner_mb?.publicURL || ds2022Yaml?.banner_mb}
          time_icon={ds2022Yaml?.time_icon?.publicURL || ds2022Yaml?.time_icon}
          coord_icon={ds2022Yaml?.coord_icon?.publicURL || ds2022Yaml?.coord_icon}
          coord_place={ds2022Yaml?.coord_place}
          time={ds2022Yaml?.time}
          arrows_icon={ds2022Yaml?.arrows_icon?.publicURL || ds2022Yaml?.arrows_icon}
          ds2022_icon_mb={ds2022Yaml?.ds2022_icon_mb}
          entrance_one_text={ds2022Yaml?.entrance_one_text}
          entrance_one_href={ds2022Yaml?.entrance_one_href}
          entrance_two_text={ds2022Yaml?.entrance_two_text}
          entrance_two_href={ds2022Yaml?.entrance_two_href}
          onClickArrow={handleClickArrow}
        />
        <article className={classnames(styles.ds2022Container)}>
          {/* 大会亮点 */}
          <Ds2022IntegralCard title={ds2022Yaml?.light_title} maskTitle={ds2022Yaml?.mask_title} dataN="大会亮点">
            <Ds2022LightCard
              cardClassName={ds2022Yaml?.dataSource_one?.cardClassName}
              className={classnames(styles.cardOne)}
              dataSource={ds2022Yaml?.dataSource_one || {}}
            />
            <div className="lg:flex lg:mt-[40px]">
              <Ds2022LightCard
                cardClassName={ds2022Yaml?.dataSource_two?.cardClassName}
                className={classnames(styles.cardTwo, 'lg:flex-1')}
                dataSource={ds2022Yaml?.dataSource_two || {}}
              />
              <Ds2022LightCard
                cardClassName={ds2022Yaml?.dataSource_three?.cardClassName}
                className={classnames(styles.cardThree, 'lg:flex-1 lg:ml-[40px]')}
                dataSource={ds2022Yaml?.dataSource_three || {}}
                onOpenPopup={handleOpenPopup}
              />
            </div>
          </Ds2022IntegralCard>
          {/* 嘉宾阵容 */}
          <Ds2022IntegralCard
            maskTitle={ds2022Yaml?.guest_mask_title}
            title={ds2022Yaml?.guest_title}
            dataN="嘉宾阵容"
            // DsSlot={

            // }
          >
            <>
              {/* pc */}
              <Ds2022GuestsSwiper className="hidden lg:block">
                {ds2022Yaml?.guest_dataSource?.map((item, index) => {
                  return <Ds2022GuestsSwiperSlide dataSource={item} key={index} />
                })}
              </Ds2022GuestsSwiper>
              {/* h5 */}
              {!isPc() && <Ds2022GuestsMbSwiper guest_dataSource_mb={ds2022Yaml?.guest_dataSource_mb} />}
            </>
          </Ds2022IntegralCard>
          {/* 大会议程 */}
          <Ds2022IntegralCard
            dataN="大会议程"
            title={ds2022Yaml?.schedule_title}
            maskTitle={ds2022Yaml?.schedule_mask_title}
          >
            <Schedule dataSource={ds2022Yaml?.schedule_dataSource} />
          </Ds2022IntegralCard>
          {/* 线上演示中心 */}
          <Ds2022IntegralCard
            title={ds2022Yaml?.online_title}
            maskTitle={ds2022Yaml?.online_mask_title}
            className="lg:block hidden "
            dataN="线上演示中心"
            DsSlot={
              <div className="w-[1200px] mx-auto h-[675px] relative">
                <StaticImage
                  className="absolute top-0 left-0 right-0 bottom-0 "
                  src="https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/icon_schedule_bg_new.png"
                  alt={ds2022Yaml?.online_title}
                />
                <div
                  className="absolute w-[785px] top-[348px] left-[80px]  text-center  h-[234px] pt-[42px]"
                  style={{
                    backgroundImage: `url(https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/icon_schedule_new.png)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                  }}
                >
                  <div className="ml-auto mr-[52px] w-[580px]">
                    <h3 className="text-[24px] font-medium text-[#000]">大家好，我是神策数据驱动大会小助理“小数点”</h3>
                    <div className="mt-[17px] text-[14px] text-[#5a5757] leading-[24px]">
                      <p>
                        我们在线上展区为大家准备了 15+ 专题的体验，有场景讲解视频、行业干货资料、指标拆解工具、Demo
                        体验等，打卡学习任务还可解锁精品课程、百果园优惠券等礼品哦~快来参与吧！
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-[107px] mt-[30px] cursor-pointer h-[30px] mx-auto text-center text-[14px] leading-[30px] text-[#000] font-medium"
                    style={{
                      backgroundImage: `url(https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/explore_button_bg.svg)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                    }}
                    onClick={handleOpenPopup}
                  >
                    立即探索
                  </div>
                </div>
              </div>
            }
          />
          {/* 往届回顾 */}
          <Ds2022IntegralCard
            title={ds2022Yaml?.review_title}
            maskTitle={ds2022Yaml?.review_mask_title}
            dataN="往届回顾"
            DsSlot={
              <div className="lg:w-[1200px] mx-auto ">
                <Ds2022ReviewSwiper dataSource={ds2022Yaml?.review_dataSource} />
              </div>
            }
          />
          <Ds2022Footer
            title={ds2022Yaml?.footer_title}
            message={ds2022Yaml?.footer_msg}
            code={ds2022Yaml?.footer_code?.publicURL || ds2022Yaml?.footer_code}
          />
        </article>
      </main>
      {/* 电梯导航栏 */}
      <ul
        ref={eleNavigation}
        id="elevator_navigation"
        className={classnames(
          'hidden lg:block fixed  text-[14px] lg:z-[999]   top-[50%] left-[0] translate-y-[-100px]',
          styles.elevatorNavigation,
        )}
      >
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 0 })}>
          {/* <div data-n="大会亮点" className={classnames(styles.navigation_item_line)}></div> */}
          <div data-n="大会亮点" className={classnames(styles.navigation_item_content)}>
            大会亮点
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 1 })}>
          {/* <div data-n="嘉宾阵容" className={classnames(styles.navigation_item_line)}></div> */}
          <div data-n="嘉宾阵容" className={classnames(styles.navigation_item_content)}>
            嘉宾阵容
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 2 })}>
          {/* <div data-n="大会议程" className={classnames(styles.navigation_item_line)}></div> */}
          <div data-n="大会议程" className={classnames(styles.navigation_item_content)}>
            大会议程
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 3 })}>
          {/* <div data-n="线上演示中心" className={classnames(styles.navigation_item_line)}></div> */}
          <div data-n="线上演示中心" className={classnames(styles.navigation_item_content)}>
            线上展区
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 4 })}>
          {/* <div data-n="往届回顾" className={classnames(styles.navigation_item_line)}></div> */}
          <div data-n="往届回顾" className={classnames(styles.navigation_item_content)}>
            往届回顾
          </div>
        </li>
      </ul>
      {/* 右侧小数点 */}
      <div
        ref={rightPoint}
        className={classnames(
          ' fixed hidden z-[999] w-[48px] h-[248px]  top-[50%] translate-y-[-50%] right-[28px]  lg:flex flex-col',
          styles.rightNav,
        )}
      >
        <div
          className={classnames('h-[66px] w-full', styles.rightPoint)}
          onClick={() => {
            setFlag(!flag)
          }}
        ></div>
        <div className={classnames('w-full h-[168px] rounded-[24px] ', styles.rightBtn, { blockFlag: flag })}>
          <div className="flex-1 relative">
            <span>赢 好 礼</span>
            <p className={classnames('absolute pt-[24px] px-[24px]  left-[-200px]', styles.miniProgramQRcode)}>
              <h3 className="text-center ">
                <img
                  className="w-[140px] h-[140px] "
                  src={require('assets/images/topic/ds2022/ds2022_qrcode.jpg').default}
                  alt=""
                />
              </h3>
              <h3 className=" text-[12px] leading-[18px]  my-[13px]">
                扫码进入小程序
                <br />
                参加活动得好礼
              </h3>
              {/* <h3 className="text-[22px] pt-[30px] leading-[24px] inline-block">
                即将上线
                <br />
                敬请期待
              </h3> */}
            </p>
          </div>
          <div className="flex-1 relative">
            <span>咨 询</span>
            <p className={classnames('absolute pt-[24px] px-[24px] text-center left-[-200px]', styles.consult)}>
              <h3 className="text-center ">
                <img
                  className="w-[140px] h-[140px]"
                  src={require('assets/images/topic/ds2022/consulting_conference_qr_code.png').default}
                  alt=""
                />
              </h3>
              <h3 className=" text-[12px] leading-[18px]   my-[13px]">
                扫码咨询
                <br />
                大会更多信息
              </h3>
            </p>
          </div>
        </div>
      </div>
      {/* 弹窗 */}
      <Ds2022Popup onClose={handleClosePopup} show={popupShow} />
    </Layout>
  )
}

export default DS2022
