/*
 * @Author: liyu
 * @Date: 2022-09-20 11:33:31
 * @LastEditTime: 2022-09-22 14:54:34
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022Popup/index.tsx
 */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classnames from 'classnames'

const Ds2022Popup = ({ show, onClose }) => {
  const handleClosePopup = () => {}
  return (
    <>
      {/* 弹窗 */}
      <div
        className={classnames('fixed hidden z-[999] bg-[#000] opacity-[0.6] top-0 left-0 right-0 bottom-0 ', {
          '!block': show,
        })}
      ></div>
      <div
        className={classnames(
          'fixed w-[90%] hidden  pb-[40px] lg:pb-auto  z-[999] text-center text-[#000] lg:pt-[25px] lg:px-[40px] lg:w-[787px] bg-[#fff]  lg:h-[596px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ',
          { '!block': show },
        )}
      >
        <div className="text-[30px] hidden lg:block font-medium leading-[42px]">线上演示中心</div>
        <div className="lg:mt-[16px] border">
          <StaticImage
            className="w-full"
            src="https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/icon_schedule_bg_new.png"
            alt=""
          />
        </div>
        <div className="lg:hidden mt-[20px] text-[16px] font-medium leading-[22px]">线上演示中心</div>
        <p className="mt-[20px] lg:mt-[34px] text-[14px] leading-[20px]">线上体验展区建设中，即将上线（10 月初） </p>
        <p className="leading-[20px] text-[14px]">敬请期待！</p>
        <div onClick={onClose} className="w-[18px] cursor-pointer h-[18px] absolute right-[16px] top-[16px]">
          <img className="w-full h-full" src={require('assets/images/topic/ds2022/close_icon.png').default} alt="" />
        </div>
      </div>
    </>
  )
}

export default Ds2022Popup
