import React from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classnames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from './index.module.less'

interface Ds2022GuestsSwiperSlidePropsState {
  dataSource: any
}

const Ds2022GuestsSwiperSlide: React.FC<Ds2022GuestsSwiperSlidePropsState> = ({ dataSource }) => {
  return (
    <div className="swiper-slide" key={1}>
      <div className="flex flex-col h-full justify-between">
        <ul className="flex-1  flex justify-evenly ">
          {/* top */}
          {dataSource?.top.map((item, index) => {
            const image = getImage(item?.person_img)
            return (
              <li className={classnames(' inline-block  w-[188px] h-[312px] relative', styles.guestsCard)} key={index}>
                <div className="relative inline-block">
                  <div className="text-center">
                    {/* <img
                      className={classnames('w-[188px]', styles.headPortrait)}
                      src={item?.person_img?.publicURL || item?.person_img}
                      alt=""
                    /> */}
                    <GatsbyImage
                      className={classnames('w-[188px]', styles.headPortrait)}
                      image={image as IGatsbyImageData}
                      alt={item?.person_name}
                      key={item?.person_name}
                    />
                    {/* <StaticImage
                      className={classnames('w-[188px]', styles.headPortrait)}
                      src="https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/person_img_00.svg"
                      alt=""
                    /> */}
                  </div>
                  <div className={styles.person_msg} key={item?.person_position}>
                    <div className="text-center whitespace-nowrap  text-[#fff] mt-[16px] text-[24px] leading-[33px] font-medium">
                      {item?.person_name}
                    </div>
                    <div className="text-center text-[#fff] text-[14px] leading-[24px]">{item?.person_position}</div>
                  </div>
                </div>
                <div className={classnames(' text-right   py-[14px]  pl-[60px]', styles.guestsDesc)}>
                  <div className=" inline-block text-left   h-auto w-[264px] text-[12px] leading-[17px] ">
                    {item?.person_desc}
                  </div>
                </div>
                <div className={classnames('absolute top-[67px] left-[224px]  min-w-[160px]', styles.guestsPosition)}>
                  <div className="text-center whitespace-nowrap text-[#fff] text-[24px] leading-[33px] font-medium">
                    {item?.person_name}
                  </div>
                  <div className="text-center text-[#fff] text-[14px] leading-[24px]">{item?.person_position}</div>
                </div>
              </li>
            )
          })}
        </ul>
        <ul className="flex-1  flex justify-evenly mt-[26px] ">
          {/* bottom */}
          {dataSource?.bottom?.map((item, index) => {
            const image = getImage(item?.person_img)
            return (
              <li className={classnames('  w-[188px] h-[312px] relative', styles.guestsCard)} key={index}>
                <div className="relative inline-block">
                  <div className="text-center">
                    {/* <img
                      className={classnames('w-[188px]', styles.headPortrait)}
                      src={item?.person_img?.publicURL || item?.person_img}
                      alt=""
                    /> */}
                    <GatsbyImage
                      className={classnames('w-[188px]', styles.headPortrait)}
                      image={image as IGatsbyImageData}
                      alt={item?.person_name}
                    />
                  </div>
                  <div className={styles.person_msg}>
                    <div className="text-center  text-[#fff] mt-[16px] text-[24px] leading-[33px] font-medium">
                      {item?.person_name}
                    </div>
                    <div className={classnames('text-center  text-[#fff] text-[13px] leading-[24px]')}>
                      {item?.person_position}
                    </div>
                  </div>
                </div>
                <div className={classnames(' text-right   py-[14px]   pl-[60px]', styles.guestsDesc)}>
                  <div className=" inline-block text-left   h-auto w-[264px] text-[12px] leading-[17px] ">
                    {item?.person_desc}
                  </div>
                </div>
                <div
                  className={classnames(
                    'absolute top-[67px] left-[224px] h-[94px] min-w-[160px]',
                    styles.guestsPosition,
                  )}
                >
                  <div className="text-center text-[#fff] text-[24px] leading-[33px] font-medium">
                    {item?.person_name}
                  </div>
                  <div className={classnames('text-center text-[#fff] text-[13px] leading-[24px]')}>
                    {item?.person_position}
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Ds2022GuestsSwiperSlide
